import notifications from '@/socket';
import { defineStore } from 'pinia';
import type { CMessage } from '@/ontology/messaging';
import { sendMsg } from '@/api/performer';
import { sanitize } from '@/utils';
import { useAuthStore } from './authentication';
import { useSessionStore } from '@/stores/session';

interface State {
    messages: CMessage[];
    performerId: number;
    fontSize: string;
    typing: any;
    LastTyped: any;
}

interface ChatMessage {
    senderType: 'ROLE_PERFORMER' | 'ROLE_CLIENT' | 'ROLE_SYSTEM' | 'ROLE_TOY';
    message: string;
    messageTranslate?: string;
}

interface TypingReceivedMessage {
    recentTyping: boolean;
    inBuffer: boolean;
}

const defaultMessage: CMessage = {
    billingStatus: 'PAID',
    content: '  ...   ',
    date: new Date(),
    folder: 'DRAFT',
    id: Date.now(),
    subject: '',
    performerId: -1,
    readStatus: 'OLD',
    sentBy: 'PERFORMER',
    translate: false,
    type: 'chat'
};

let typingTimeoutRef: number = 0;

export const useChatStore = defineStore('Chat', {
    state: (): State => ({
        messages: [],
        performerId: -1,
        fontSize: 'small',
        typing: false,
        LastTyped: 0
    }),
    actions: {
        initialize() {
            notifications.subscribe('msg', this.handleMessage);
            notifications.subscribe('msgt', this.handleMessage);
            notifications.subscribe('typing_received', this.handleTyping);
        },

        handleMessage({ senderType, message, messageTranslate }: ChatMessage) {
            const user = useAuthStore();
            let sentBy: 'CLIENT' | 'PERFORMER' | 'TOY' | 'SYSTEM' = 'SYSTEM';
            switch (senderType) {
                case 'ROLE_PERFORMER':
                    sentBy = 'PERFORMER';
                    break;
                case 'ROLE_CLIENT':
                    sentBy = 'CLIENT';
                    break;
                case 'ROLE_TOY':
                    sentBy = 'TOY';
                    break;
            }

            const msg: CMessage = {
                ...defaultMessage,
                ...{
                    date: new Date(),
                    id: Date.now(),
                    folder: 'INBOX',
                    performerId: user.account.id,
                    content: sanitize(message),
                    contentTranslate: messageTranslate ? sanitize(messageTranslate) : null,
                    sentBy: sentBy,
                    translate: !!messageTranslate
                }
            };

            this.messages.push(msg);
        },

        handleTyping({ recentTyping, inBuffer }: TypingReceivedMessage) {
            this.typing = recentTyping || inBuffer;

            if (typingTimeoutRef) {
                window.clearTimeout(typingTimeoutRef);
            }

            typingTimeoutRef = window.setTimeout(() => (this.typing = false), 3000);
        },

        sendTypingMessage() {
            const customer = useSessionStore();
            const currentTime = Math.floor(Date.now() / 1000);

            if (currentTime - this.LastTyped < 3 || !customer.clientData) {
                return;
            }

            notifications.sendEvent({
                event: 'typing_received',
                receiverId: customer.clientData.id,
                receiverType: 'ROLE_CLIENT',
                content: {
                    recentTyping: true
                }
            });

            this.LastTyped = currentTime;
        },

        clearMessages() {
            this.messages = [];
        },

        resizeFont() {
            if (this.fontSize === 'small') {
                this.fontSize = 'medium';
            } else if (this.fontSize === 'medium') {
                this.fontSize = 'large';
            } else if (this.fontSize === 'large') {
                this.fontSize = 'xlarge';
            } else {
                this.fontSize = 'small';
            }
        },

        async send(content: string) {
            const customer = useSessionStore();
            content = sanitize( content );
            if (!customer.clientData) {
                return;
            }

            const { error, result } = await sendMsg({ msg: content, receiver: customer.clientData.id });
            if (error) {
                return error;
            }

            this.handleMessage({ senderType: 'ROLE_PERFORMER', message: content });
        }
    }
});
