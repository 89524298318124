export type LovenseClientState = 'disconnected' | 'connected' | 'in_use';
export type LovenseClientTransition = 'connect' | 'heartbeat' | 'disconnect' | 'available' | 'vibrate' | 'vibrateDone' | 'cancelVibration';
export const devicesSupported = ['nora', 'max', 'lush', 'lush 3', 'hush', 'ambi', 'edge', 'domi', 'osci', 'ferri', 'dolce', 'hyphy', 'xmachine', 'gemini', 'gravity', 'tenera', 'flexer', 'exomoon', 'lapis', 'ridge', 'vulse', 'calor', 'diamo'];
export const devicePresetSupported = ['lush', 'hush', 'ambi', 'edge', 'domi', 'osci', 'ferri', 'dolce', 'hyphy', 'xmachine', 'gemini', 'gravity', 'tenera', 'flexer', 'exomoon', 'lapis', 'ridge', 'vulse', 'calor', 'diamo'];
export const deviceThrustingSupported = ['xmachine', 'gravity'];
export const deviceFingeringSupported = ['flexer'];
export const deviceSuctionSupported = ['tenera'];
export const deviceRotateSupported = ['nora', 'ridge'];

export interface Transition<T, X extends string> {
    from: T;
    to: T;
    action: X;
}

export interface ToyCapabiltyData {
    name: string;
    vibrate?: boolean;
    rotate?: boolean;
    pump?: number;
    preset?: number;
}

export type ToyPreset = 'pulse' | 'wave' | 'fireworks' | 'earthquake' | 'random';
export type ToyMotionType = 'vibrate' | 'rotate' | 'pump' | 'thrusting' | 'fingering' | 'suction' | 'preset';

export interface LovenseClient {
    appVersion: string;
    deviceId: string;
    deviceCode: string;
    domain: string;
    httpPort: number;
    httpsPort: number;
    platform: string;
    toyJson: string;
    toyList: any[];
    wsPort: number;
    wssPort: number;
}

export interface LovenseRule {
    id: number; //we need this to identify the rule
    toys: LovenseDevice[]; //undefined is all...
    amount: number; // 1 = 40 credits etc ...
    reactionTime: number; // reaction time
    intensity: number; //intensity of toy
    motions?: ToyMotionType[]; //array of motions
}

export interface LovenseSpecialRule {
    id: number;
    amount: number;
    toys: LovenseDevice[]; //undefined is all...
    reactionTime: number;
    pattern: ToyPreset;
    checked: boolean;
}

export interface LovenseDevice {
    battery?: number;
    connected: boolean;
    fVersion?: string;
    hVersion?: string;
    id: string;
    active: boolean;
    name?: string;
    nickname?: string;
    toyType?: string;
    speed: number;
    rotationSpeed: number;
    pumpSpeed: number;
    thrustingSpeed: number;
    fingeringSpeed: number;
    suctionSpeed: number;
    patternRule: string;
    patternStrength: string;
    presetKind: ToyPreset;
    vibrate?: boolean;
    rotate?: boolean;
    pump?: boolean;
    pattern?: boolean;
    preset?: boolean;
    thrusting?: boolean;
    fingering?: boolean;
    suction?: boolean;
    motions?: ToyMotionType[];
}

export interface LovenseVibrateData {
    toy?: LovenseDevice;
    toys?: LovenseDevice[];
    clientName?: string;
    timeInSec: number;
    rule?: string;
    special?: string;
}
