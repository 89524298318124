import type { User, Role, Performer } from '@/ontology/performer';
import { delEte, get, post, put } from '../fetch-extension';
import config from '../../config';

export async function update(performer: Performer) {
    const { error, result } = await put<any>(`performer/performer_accounts/${performer.id}`, { body: performer });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function updateServices(performerId: number, services: Object) {
    const { error, result } = await put<any>(`performer/performer_account/${performerId}/performer_services`, { body: services });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function setPayment(performerId: number, payload: Object){
    const { error, result } = await put<any>(`performer/performer_account/${performerId}/performer_payment`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function setExtra(performerId: number, payload: any){
    const { error, result } = await put<any>(`performer/performer_account/${performerId}/extra-set`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function earningsOverview(performerId: number) {
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/earnings_left`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function startCasting(performerId: number) {
    const { error, result } = await post<any>(`performer/performer_accounts/${performerId}/broadcast/video`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function performerReady(payload: any){
    const { error, result } = await post<any>(`session/performer_ready`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function timeout(payload: any){
    const { error, result } = await post<any>(`session/timeout`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function end(payload: any){
    const { error, result } = await post<any>(`session/end`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function sendMsg(payload: any){
    const { error, result } = await post<any>(`session/chat_message`, { body: payload });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getPersonal(performerId: number){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/performer_details`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getPayment(performerId: number){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/performer_payment`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getClients(performerId: number, query: Object){
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/clients`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getClient(data: any, query: Object){
    const { error, result } = await get<any>(`performer/performer_accounts/${data.performerId}/${data.type}/${data.customerId}`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getIVR(performerId: number){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/performer_ivr`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getMemo(performerId: number, clientId: number){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/memo/client_account/${clientId}`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getMemos(performerId: number, query: Object){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/memo`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getPhotos(performerId: number, query: Object){
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/photos/group`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getMedias(performerId: number, query: Object){
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/medias/group`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getUrgent(performerId: number){
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/urgent`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getVIPPrices(performerId: number){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/vip-prices`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getExtra(performerId: number){
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/extra-set`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function postTranslateDescription(payload: any){
    const body = { text: payload.text };
    const { error, result } = await post<any>(`performer/performer_accounts/${payload.performerId}/descriptions/${payload.prevLang}/translate/${payload.nextLang}`, { body });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function postMemo(payload: any){
    const body = { content: payload.content, client: { id: payload.clientId }};
    const { error, result } = await post<any>(`performer/performer_account/${payload.performerId}/memo`, { body });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function putMemo(payload: any){
    const body = { content: payload.content, client: { id: payload.clientId }};
    const { error, result } = await put<any>(`performer/performer_account/${payload.performerId}/memo/${payload.id}`, { body });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function blockCustomers(type: any, payload: any){
    const body = payload;
    const types = type ? `annon_block/${payload.client.id}` : `block`;
    const { error, result } = await post<any>(`performer/performer_account/${payload.performerAccount.id}/${types}`, { body });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function unBlockCustomers(payload: any, blockId: string){
    const body = payload;
    const { error, result } = await delEte<any>(`performer/performer_account/${body.performerAccount.id}/block/${blockId}`, { body });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function listEarnings(performerId: number, query: Object | any){
    query.date = query.date / 1000;
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/earnings`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function listInvoices(performerId: number, query: Object | any){
    const { error, result } = await get<any>(`performer/performer_accounts/${performerId}/payments`, { query });

    if (error) {
        return { error };
    }

    return { result: result };
}

export function invoiceUrl(invoiceId: number): string {
    return `${config.FullApiUrl}api/performer/payments/${invoiceId}/invoice`;
}